<template>
    <div class="demo">
      
    </div>
  </template>
  
  <script>
  
  
  export default {
 
    
  }
  </script>
  
  <style>
  .demo { 
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  input {
    width: 260px;
    padding: 0.5rem;
  }
  
  ul {
    width: 100%;
    color: rgba(30, 39, 46,1.0);
    list-style: none;
    margin: 0;
    padding: 0.5rem 0 .5rem 0;
  }
  li {
    margin: 0 0 0 0;
    border-radius: 5px;
    padding: 0.75rem 0 0.75rem 0.75rem;
    display: flex;
    align-items: center;
  }
  li:hover {
    cursor: pointer;
  }
  
  .autosuggest-container {
    display: flex;
    justify-content: center;
    width: 280px;
  }
  
  #autosuggest { width: 100%; display: block;}
  .autosuggest__results-item--highlighted {
    background-color: rgba(51, 217, 178,0.2);
  }
  </style>